import { Bookings } from '../../../types';

export const bookingsMapper = (bookings: Bookings): Bookings => ({
  ...bookings,
  groups: bookings.groups.map((group) => ({
    ...group,
    bookings: group.bookings.map((booking) => ({
      ...booking,
      offering: bookings.relatedOfferings.find(
        (offering) => offering.id === booking.offeringId,
      )!,
    })),
  })),
});
