
  import userController from '/home/builduser/work/7de8fbf09bf2a439/packages/bookings-my-bookings-widget/src/components/MyBookings/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/controllerWrapper.js';

  
const wrapController = null;


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://c183baa23371454f99f417f6616b724d@sentry.wixpress.com/5557',
    id: 'undefined',
    projectName: 'bookings-my-bookings-widget',
    teamName: 'bookings',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["wix-bookings-client","wix-bookings-visitor"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/7de8fbf09bf2a439/packages/bookings-my-bookings-widget/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var biConfig = null;

  var defaultTranslations = {"app.my-bookings-widget.header.title":"Manage Your Bookings","app.my-bookings-widget.header.subtitle":"View, reschedule or cancel your bookings and easily book again.","app.my-bookings-widget.timezone-dropdown.label":"Time Zone:","app.my-bookings-widget.upcoming-tab.label":"Upcoming","app.my-bookings-widget.history-tab.label":"History","app.my-bookings-widget.empty-state.no-upcoming-bookings.label":"You've got nothing booked at the moment.","app.my-bookings-widget.empty-state.no-upcoming-bookings.button.label":"Check Out Our Services","app.my-bookings-widget.empty-state.no-history-bookings.label":"We’re looking forward to meeting you.","app.my-bookings-widget.empty-state.no-history-bookings.button.label":"Check Out Our Services","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.tabs.text":"Text","app.settings.design.general.text-style-title":"Text Style","app.settings.design.general.page-title":"Page title","app.settings.design.general.page-subtitle":"Page subtitle","app.settings.font-picker.title":"Choose your font","app.settings.tabs.design.tabs.general":"General","app.settings.tabs.design.tabs.with-bookings":"With Bookings","app.settings.tabs.design.tabs.no-bookings":"No Bookings","app.settings.tabs.text.tabs.general":"General","app.settings.tabs.text.tabs.no-bookings":"No Bookings","app.settings.text.general.page-title":"Title","app.settings.text.general.page-subtitle":"Subtitle","app.settings.reset-to-original-design":"Reset to original design"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/7de8fbf09bf2a439/packages/bookings-my-bookings-widget/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Wix Bookings",
    componentName: "MyBookings",
    appDefinitionId: "13d21c63-b5ec-5912-8397-c3a5ddb27a97",
    componentId: "261fd12d-a3ac-4ee8-8913-09eceb8b1d3e",
    projectName: "bookings-my-bookings-widget",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/7de8fbf09bf2a439/packages/bookings-my-bookings-widget/src/components/MyBookings/controller.ts",
  };

  export const controller = _controller
  export default controller;
